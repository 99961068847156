
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import TeamCreateDialog from '@/modules/team/components/create-dialog.vue'
import TeamDeleteDialog from '@/modules/team/components/delete-dialog.vue'
import TeamMemberTable from '@/modules/team/components/member-table.vue'
import { TeamModule } from '@/modules/team/store'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  components: {
    Menu,
    TeamCreateDialog,
    TeamDeleteDialog,
    TeamMemberTable,
    TeamSelectionBar: () => import('@/modules/team/components/selectionbar/index.vue')
  },
  name: 'OrganizationTeams'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)

  get currentTeamId () {
    return this.$queryValue('team')
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get currentTeam () {
    return this.teamModule.teams.find(o => o.id === this.currentTeamId)
  }

  get teamsListStatus () {
    return this.teamModule.teamsListStatus
  }

  get organizationUsersListStatus () {
    return this.organizationModule.organizationUsersListStatus
  }

  get teams () {
    return this.teamModule.teams
  }

  @Watch('teamsListStatus.success')
  onTeamListStatusSuccessChanged (success: boolean) {
    if (success && !this.currentTeam && this.teamModule.teams.length) {
      this.$replaceQuery({
        team: this.teamModule.teams[0]?.id
      })
    }
  }

  @Watch('currentOrganizationId')
  async onCurrentOrganizationIdChanged (organizationId: string) {
    if (this.teamsListStatus.successInfo.organizationId !== organizationId) {
      await this.teamModule.teamsList(organizationId)
    }
  }

  mounted () {
    analytics.organizationTeamsScreen.track(this, {
      organizationId: [this.currentOrganization.id]
    })

    if (this.teamsListStatus.idle || (this.teamsListStatus.success && this.teamsListStatus.successInfo.organizationId !== this.currentOrganization.id)) {
      this.teamModule.teamsList(this.currentOrganization.id)
    } else if (!this.currentTeam && this.teamModule.teams.length) {
      this.$replaceQuery({
        team: this.teamModule.teams[0]?.id
      })
    }

    if (this.organizationUsersListStatus.idle || (this.organizationUsersListStatus.success && this.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganization.id)) {
      this.organizationModule.organizationUsersList(this.currentOrganization.id)
    }
  }
}
